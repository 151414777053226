@import './src/components/layout/Layout.scss';

@font-face {
  font-family: "Omnes Regular";
  src: url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.eot"); /* IE9*/
  src: url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.woff2") format("woff2"), /* chrome、firefox */
  url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.woff") format("woff"), /* chrome、firefox */
  url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/assets/fonts/omnes-regular/0c098ce56cd105e2723ca6b42ca24407.svg#Omnes Regular Regular") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Omnes Semibold";
  src: url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.eot"); /* IE9*/
  src: url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.woff2") format("woff2"), /* chrome、firefox */
  url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.woff") format("woff"), /* chrome、firefox */
  url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/assets/fonts/omnes-regular/827efd408950e4d94af5e60b9a37c5b8.svg#Omnes Regular Regular") format("svg"); /* iOS 4.1- */
}

html,
body {
  font-family: 'Omnes Regular', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #290F73;
}
