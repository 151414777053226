.layout {
  background-image: url('/assets/img/img_background.jpg');
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;

  @media all and (max-width: 1100px) {
    background-image: url('/assets/img/img_background_mobile.jpg');
    height: auto;
    overflow: auto;
  }

  &__left-image {

    &__img {
      width: auto;
      height: 100vh;
    }
  }

  &__content {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__inner {

      &__title {
        padding-bottom: 30px;

        @media all and (max-width: 1100px) {
          padding: 0 30px 45px;
        }

        &__img {
          max-width: 100%;
        }
      }

      &__mobile-image {

        &__img {
          max-width: 100%;
        }
      }

      &__info {
        margin-top: 30px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__item {

          &--centered {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          &__qr-code {
            display: inline-block;
            padding: 10px 10px 5px;
            border-radius: 10px;
            background-color: #fff;
          }

          &__data {
            font-size: 17pt;
            padding: 0 30px;

            &__bold {
              font-family: 'Omnes Semibold', sans-serif;
            }
          }

          &__logo {
            padding-right: 30px;

            @media all and (max-width: 1100px) {
              padding: 30px 0;
              text-align: center;
            }

            &__img {
              @media all and (max-width: 1100px) {
                width: 33%;
              }
            }
          }

          &__mobile-download {

            &__title {
              font-size: 16pt;
              text-align: center;
              color: #fff;
            }

            &__link {
              display: block;
              width: 100%;
              text-align: center;

              &__img {
                max-width: 100%;
                margin-bottom: 15px;
              }
            }

            &__refresh {
              font-size: 16pt;
              text-align: center;
              color: #fff;
              padding-top: 30px;

              & > a {
                text-decoration: underline;
                color: #fff;
              }
            }

          }

          &__mobile-image {

            &__img {
              max-width: 100%;
            }
          }
        }
      }

      &__steps {
        padding-top: 45px;
        display: flex;
        flex-direction: row;

        @media all and (max-width: 1100px) {
          flex-direction: column;
        }

        &__single {
          display: flex;
          flex-direction: row;

          @media all and (max-width: 1100px) {
            margin-bottom: 30px;
          }

          &__number {
            padding-top: 6px;

            &__inner {
              font-family: 'Omnes Semibold', sans-serif;
              background-color: #FFC42C;
              color: #fff;
              border-radius: 50%;
              text-align: center;
              width: 40px;
              height: 40px;
              font-size: 24px;
              padding-top: 2px;
            }
          }

          &__desc {
            font-family: 'Omnes Regular', sans-serif;
            font-size: 14pt;
            color: #fff;
            padding-left: 15px;

            & > strong {
              font-family: 'Omnes Semibold', sans-serif;
            }
          }
        }
      }
    }
  }
}
